import { Button, Center, Loader } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";
import SurveyJsDashboard from "../SurveyJsDashboard";
import {
  useGetApiCallGetStepResultByCall,
  useGetApiStepGetStepById,
} from "../../../api/endpoints";
import { useTranslation } from "react-i18next";

const ReportByStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { stepId, callId } = useParams();
  const { data: step } = useGetApiStepGetStepById({ stepId });
  const { data: results } = useGetApiCallGetStepResultByCall({
    StepId: stepId,
    callId: callId,
  });
  console.log("STEP: ", results);
  if (!results || !step) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <Button
        variant="subtle"
        style={{ marginLeft: -14 }}
        leftIcon={<IconArrowLeft />}
        onClick={() => navigate(-1)}
      >
        {t("pages.admin.section.go-back")}
      </Button>
      <SurveyJsDashboard results={results} step={step} />
    </>
  );
};

export default ReportByStep;
