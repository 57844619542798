import { Select } from "@mantine/core";
import useSelectedLanguage from "../hooks/useSelectedLanguage";
import { useConfig } from "../hooks/useConfig";
import { useCallback, useEffect } from "react";

export const SelectLanguage = () => {
  const config = useConfig();
  const [selectedLanguage, setSelectedLanguage] = useSelectedLanguage(
    config.defaultLanguage
  );
  const changeLanguage = (lang: string) => {
    setSelectedLanguage(lang, 30);
    window?.location?.reload();
  };



  return (
    <Select
      onChange={changeLanguage}
      value={selectedLanguage ?? config.defaultLanguage }
      size={"sm"}
      data={[
        { value: "en", label: "English" },
        { value: "it", label: "Italiano" },
      ]}
    />
  );
};
