import {
  Group,
  Text,
  NumberInput,
  Select,
  TextInput,
  Textarea,
  Switch,
  Flex,
  Button,
  Divider,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type FileContent = {
  file_name: string,
  file_description: string,
  file_type: string,
  dimensions: number,
  mandatory: false,
}

export default function Uploader(
  {
    content,
    setData
  }:
    {
      content?: string | undefined,
      setData: (value: string) => void;
    }
) {
  const initialData: FileContent = {
    file_name: "",
    file_description: "",
    file_type: "",
    dimensions: 0,
    mandatory: false,
  };
  const [files, setFiles] = useState<FileContent[]>(content ? JSON.parse(content) : [initialData]);

  const { t } = useTranslation();
  const fileTypeList = [
    { value: "pdf", label: "PDF" },
    { value: "excel", label: "EXCEL" },
    { value: "word", label: "WORD" },
    { value: "image", label: "IMMAGINE" },
  ];

  
  const handleFormChange = (index: number, value: any, nameValue: keyof FileContent) => {
    let data = [...files];
    if (data.length) {
      //@ts-ignore
      data[index][nameValue] = value;
    }
    setFiles(data);
    setData(JSON.stringify(data))
  };

  const handleAddForm = () => {
    let newfield = { ...initialData };
    setFiles([...files, newfield]);
  };

  let handleRemoveForm = (index: any) => {
    var data = [...files];
    data.splice(index, 1);
    setFiles(data);
  };

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
        }}
      >
        {files.map((value: any, index: any) => {
          return (
            <div key={index}>
              <TextInput
                defaultValue={value.file_name}
                label={t("components.upload.file-name")}
                onChange={(e: any) =>
                  handleFormChange(index, e.target.value, "file_name")
                }
              />
              <Textarea
                defaultValue={value.file_description}
                label={t("components.upload.file-description")}
                mt={20}
                onChange={(e: any) =>
                  handleFormChange(index, e.target.value, "file_description")
                }
              />
              <Group position="left" mt={20} align="end">
                <Select
                  label={t("components.upload.file-type")}
                  data={fileTypeList}
                  mr={20}
                  clearable
                  defaultValue={value.file_type}
                  onChange={(value: any) =>
                    handleFormChange(index, value, "file_type")
                  }
                />
                <NumberInput
                  defaultValue={value.dimensions}
                  label={t("components.upload.file-dimensions-max")}
                  onChange={(value: any) =>
                    handleFormChange(index, value, "dimensions")
                  }
                />
                <Flex direction={"column"} ml={20}>
                  <Text size={14}>{t("components.upload.mandatory")}</Text>
                  <Switch
                    onLabel={t("components.upload.yes")}
                    offLabel={t("components.upload.no")}
                    size="lg"
                    defaultValue={value.mandatory}
                    onChange={(event: any) =>
                      handleFormChange(index, event.target.checked, "mandatory")
                    }
                  />
                </Flex>

                <Button
                  color="red"
                  variant="subtle"
                  onClick={() => handleRemoveForm(index)}
                >
                  {t("components.upload.remove")}
                </Button>
              </Group>
              <Divider mt={20} mb={20} />
            </div>
          );
        })}
      </form>
      <Button
        leftIcon={<IconPlus size={20} />}
        variant="subtle"
        fw={600}
        onClick={handleAddForm}
      >
        {t("components.upload.add-another-file")}
      </Button>
    </>
  );
}
