import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CallDto } from "../../api/model";
import { DatePickerInput } from "@mantine/dates";
import {
  useGetApiCallGetCallTypes,
  useGetApiCallGetCallById,
  putApiCallUpdateCall,
  getGetApiCallGetCallByIdKey,
} from "../../api/endpoints/index";
import { Button, Select, SelectItem, TextInput } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";

function DateCast(str: string | undefined): Date {
  if (str == undefined) return new Date();
  str = str.endsWith("+0000") ? str : str + "+0000";
  let d = new Date(str);
  return d;
}
function EditCall() {
  const { callId } = useParams();

  const { t } = useTranslation();

  const { data: callTypes, isLoading: callTypeLoading } =
    useGetApiCallGetCallTypes();

  const { data: call, isLoading: callLoading } = useGetApiCallGetCallById(
    {
      callId: callId,
    },
    {
      swr: {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    }
  );
  const navigate = useNavigate();

  const form = useForm<CallDto>({});
  const [openingDate, setOpeningDate] = useState<Date>(
    DateCast(call?.openingAt)
  );
  const [closingDate, setClosingDate] = useState<Date>(
    DateCast(call?.closingAt)
  );

  useEffect(() => {
    form.setValues({
      ...call,
      openingAt: DateCast(call?.openingAt).toDateString(),
      closingAt: DateCast(call?.closingAt).toDateString(),
    });
    setOpeningDate(DateCast(call?.openingAt));
    setClosingDate(DateCast(call?.closingAt));
  }, [call]);

  if (callTypeLoading || callLoading)
    return <div>{t("pages.admin.loading")}</div>;
  if (!call) return <div>{t("pages.admin.call-not-found")}</div>;
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        putApiCallUpdateCall({
          ...values,
          openingAt: openingDate?.toISOString(),
          closingAt: closingDate?.toISOString(),
        })
          .catch((res) => {
            showNotification({
              title: t("pages.admin.error-updating-call"),
              message: res,
              color: "red",
            });
          })
          .then((res) => {
            showNotification({
              title: t("pages.admin.update-call"),
              message: t("pages.admin.call-updated-successfully"),
            });
            mutate(getGetApiCallGetCallByIdKey);
            navigate("/admin", { state: "calls" });
          });
      })}
    >
      <TextInput
        withAsterisk
        label={t("pages.admin.name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("pages.admin.description")}
        {...form.getInputProps("description")}
      />
      <DatePickerInput
        label={t("pages.admin.pick-opening-date")}
        placeholder={t("pages.admin.pick-opening-date")}
        value={openingDate}
        onChange={(v) => setOpeningDate(new Date(v!))}
        size={"sm"}
        type={"default"}
      />
      <DatePickerInput
        label={t("pages.admin.pick-closing-date")}
        placeholder={t("pages.admin.pick-closing-date")}
        value={closingDate}
        onChange={(v) => setClosingDate(new Date(v!))}
        size={"sm"}
        type={"default"}
      />
      <Select
        label={t("pages.admin.call-type")}
        data={
          callTypes?.map<SelectItem>((c) => {
            return { label: c.name, value: c.id } as any;
          }) ?? []
        }
        {...form.getInputProps("callTypeId")}
      />
      <Button type="submit">{t("pages.admin.update")}</Button>
      <Button
        onClick={() => navigate("/admin", { state: "calls" })}
        color="red"
      >
        {t("pages.admin.cancel")}
      </Button>
    </form>
  );
}

export default EditCall;
