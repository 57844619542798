import {
  Button,
  Stack,
  TextInput,
  Text,
  Textarea,
  Divider,
  Card,
  Title,
  Space,
  Grid,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useRef } from "react";
import { Step } from "../../api/model";
import { create } from "zustand";
import { CreateCallTypeDto } from "../../api/model/createCallTypeDto";
import { postApiCallCreateCallType } from "../../api/endpoints";
import { CreateStepDto } from "../../api/model/createStepDto";
import produce from "immer";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useNewCallStore } from "./CreateCall";
import { useTranslation } from "react-i18next";

type NewStepsStore = {
  steps: { [n: number]: CreateStepDto };
  addNewStep: (newStep: Step) => void;
  clearSteps: () => void;
  editStep: (
    stepIndex: number,
    key: keyof CreateStepDto,
    value: string
  ) => void;
};

export const useNewStepsStore = create<NewStepsStore>((set) => ({
  steps: [],
  addNewStep: (newStep: CreateStepDto) =>
    set((state) =>
      produce(state, (draft) => {
        draft.steps[Object.keys(draft.steps).length] = newStep;
      })
    ),
  clearSteps: () =>
    set((state) =>
      produce(state, (draft) => {
        draft.steps = [];
      })
    ),
  editStep: (stepKey: number, key: keyof CreateStepDto, value: string) =>
    set((state) =>
      produce(state, (draft) => {
        //@ts-ignore
        draft.steps[stepKey][key] = value;
      })
    ),
}));

function CreateCallType() {
  const navigate = useNavigate();

  const { addNewStep, clearSteps } = useNewStepsStore((store) => store);
  const { t } = useTranslation();
  const { setCall, call } = useNewCallStore((store) => store);
  const steps = useRef(useNewStepsStore.getState().steps);
  useEffect(
    () =>
      useNewStepsStore.subscribe((state) => {
        steps.current = state.steps;
      }),
    []
  );

  const form = useForm<CreateCallTypeDto>({
    initialValues: {
      name: "",
      description: "",
      steps: [],
    },
  });

  return (
    <>
      <Card shadow="lg" p="lg" radius="md" withBorder>
        <Title order={3}>{t("pages.admin.new-call-type")}</Title>
        <Space h="md" />
        <form
          onSubmit={form.onSubmit((values) => {
            postApiCallCreateCallType({
              ...values,
              steps: Object.values(steps.current),
            }).then((res) => {
              showNotification({
                title: t("pages.admin.created"),
                message: t("pages.admin.calltype-created-successfully"),
              });
              clearSteps();
              setCall({ ...call, callTypeId: res?.id });
              navigate(-1);
            });
          })}
        >
          <TextInput
            withAsterisk
            label={t("pages.admin.name")}
            {...form.getInputProps("name")}
          />
          <Textarea
            withAsterisk
            label={t("pages.admin.description")}
            {...form.getInputProps("description")}
          />
          <Divider my={20} />
          <Text>{t("pages.admin.steps")}</Text>
          <Stack my="lg">
            <Button
              variant="outline"
              onClick={() =>
                addNewStep({
                  name: "",
                  description: "",
                  content: "",
                  sort: 0,
                  stepTypeId: "",
                  expectedAnswers: "",
                })
              }
            >
              {t("pages.admin.add-new-step")}
            </Button>
          </Stack>
          <Grid columns={12}>
            <Grid.Col span={1}>
              <Button
                variant="filled"
                color="blue"
                mt="lg"
                radius="md"
                type="submit"
              >
                {t("pages.admin.create")}
              </Button>
            </Grid.Col>
            <Grid.Col span={3}>
              <Button
                variant="filled"
                color="red"
                mt="lg"
                radius="md"
                onClick={() => {
                  clearSteps();
                  navigate(-1);
                }}
              >
                {t("pages.admin.cancel")}
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Card>
    </>
  );
}

export default CreateCallType;
