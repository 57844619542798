import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Divider,
  Title,
  Text,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  getGetApiOrganizationGetOrganizationByIdKey,
  putApiOrganizationUpdateOrganization,
  useGetApiOrganizationGetOrganizationById,
} from "../../api/endpoints/index";
import { OrganizationDto } from "../../api/model";
import { IconInfoCircle } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../hooks/useConfig";

function DateCast(str: string | undefined): Date {
  if (str == undefined) return new Date();
  str = str + "+0000";
  let d = new Date(str);
  return d;
}
const OrganizationEdit = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const config = useConfig();

  const { data: organization, error: orgError } =
    useGetApiOrganizationGetOrganizationById({
      orgId: organizationId!,
    });

  const form = useForm<OrganizationDto>({
    initialValues: {
      city_name: "",
      contact_role: "",
      country: "",
      date_of_foundation: "",
      id: "",
      name: "",
      reg_number: "",
      street_name: "",
      contact_name: "",
      contact_surname: "",
      contact_email: "",
      personalData: false,
      selfCertification: false,
      termsOfService: false,
      nordicCountry: false,
      preFunding: false,
      chnageInLife: false,
    },
  });
  useEffect(() => {
    console.log(organization);
    form.setValues({ ...organization });
  }, [organization]);

  if (orgError) {
    return (
      <div>
        {t("pages.organizations.error")} {orgError.message}
      </div>
    );
  }
  if (!organization) {
    return <div>{t("pages.organizations.loading")}</div>;
  }

  return (
    <Box mx="auto">
      <form
        onSubmit={form.onSubmit((values) =>
          putApiOrganizationUpdateOrganization(values).then((res) => {
            console.log(res);
            if (res) {
              mutate(
                getGetApiOrganizationGetOrganizationByIdKey({
                  orgId: organizationId!,
                })
              );
              showNotification({
                message: t("pages.organizations.updated-succesfully"),
              });
              navigate(-1);
            }
          })
        )}
      >
        <Stack mt="xl">
          <h5>{t("pages.organizations.general-info")}</h5>
          <TextInput
            withAsterisk
            label={t("pages.organizations.company-name")}
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.registration-number")}
            {...form.getInputProps("reg_number")}
            rightSection={
              <Tooltip
                multiline
                width={220}
                label={t("pages.organizations.company-tax")}
              >
                <div>
                  <IconInfoCircle size={14}></IconInfoCircle>
                </div>
              </Tooltip>
            }
          />
        </Stack>
        <Stack mt="xl">
          <h5>{t("pages.organizations.address")}</h5>
          <TextInput
            withAsterisk
            label={t("pages.organizations.address")}
            {...form.getInputProps("street_name")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.city-name")}
            {...form.getInputProps("city_name")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.country-name")}
            {...form.getInputProps("country")}
          />
        </Stack>
        <Stack mt="xl">
          <h5>{t("pages.organizations.contact-person")}</h5>
          <TextInput
            withAsterisk
            label={t("pages.organizations.name")}
            {...form.getInputProps("contact_name")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.surname")}
            {...form.getInputProps("contact_surname")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.role-organization")}
            {...form.getInputProps("contact_role")}
          />
          <TextInput
            withAsterisk
            label={t("pages.organizations.email")}
            placeholder={t("pages.organizations.email-placeholder")}
            {...form.getInputProps("contact_email")}
          />
          <Divider my={20}></Divider>
          <Title order={4}>
            {t("pages.organizations.processing-personal-data")}
          </Title>
          <Text size={"sm"}>
            {config.dataConsentText
              ? config.dataConsentText
              : t("pages.organizations.processing-personal-data-documentation")}
          </Text>
          <Checkbox
            mt="md"
            size="xs"
            label={t("pages.organizations.agree")}
            {...form.getInputProps("personalData", { type: "checkbox" })}
          />
        </Stack>

        <Group position="right" mt="md">
          <Button
            variant="filled"
            color="blue"
            mt="lg"
            radius="md"
            type="submit"
            disabled={
              !form.values.personalData ||
              !form.values.name ||
              !form.values.reg_number ||
              !form.values.city_name ||
              !form.values.country ||
              !form.values.street_name ||
              !form.values.contact_email ||
              !form.values.contact_name ||
              !form.values.contact_role ||
              !form.values.contact_surname
            }
          >
            {t("pages.organizations.update")}
          </Button>
          <Button
            variant="filled"
            color="red"
            mt="lg"
            radius="md"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("pages.organizations.cancel")}
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default OrganizationEdit;
