import {
  Anchor,
  Button,
  Center,
  Group,
  Image,
  Text,
  Title,
} from "@mantine/core";
import { useAuth } from "../auth/AuthContext";
import { IconUser, IconUserPlus } from "@tabler/icons";
import { ReactComponent as Logo } from "../assets/svg/OI_logo.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { ConfigProvider, useConfig } from "../hooks/useConfig";

const WelcomePage = () => {
  // const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const config = useConfig();
  const auth  = useAuth();
  const isAuthenticated = !(!auth.isAuthenticated || !auth.access_token || auth.access_token == "" || auth.access_token == "null")  
  if (isAuthenticated) {
    navigate("/calls");
    return <Loading></Loading>;
  }
  return (
    <Center h="80%">
      <div>
        {config.logoBase64 ? (
          <Image src={config.logoBase64} width={200} />
        ) : (
          <Logo width={300} />
        )}
        <Title order={1} mt={60}>
          {config.name ?? t("pages.welcome-starimpact")}
        </Title>
        <Text size="xl" mt={10}>
          {config.message ?? t("pages.create-account-register-organization")}
        </Text>
        <Group my={50} align="center">
          <Anchor
            href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/authorize?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}&scope=offline_access&locale=it`}
          >
            <Button
              variant="light"
              radius="xl"
              leftIcon={<IconUser size={14} />}
            >
              {t("pages.login")}
            </Button>
          </Anchor>
          <Anchor
            href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/register?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}&locale=it`}
          >
            <Button
              variant="filled"
              radius="xl"
              leftIcon={<IconUserPlus size={14} />}
            >
              {t("pages.register")}
            </Button>
          </Anchor>
        </Group>
      </div>
    </Center>
  );
};

export default () => (
  <ConfigProvider>
    <WelcomePage />
  </ConfigProvider>
);
