import styled from "@emotion/styled";
const PostIt = styled.div`
  background-color: #fffdcd;
  border-radius: 10px;
  color: #333;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 15px;
  margin: 1rem 0;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 100%;
`;
export default PostIt;
