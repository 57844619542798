import { Text, Button } from "@mantine/core";
import { useGetApiOrganizationGetOrganizations } from "../api/endpoints";
import PostIt from "./PostIt";
import { openModal } from "@mantine/modals";
import RegisterOrganization from "./forms/RegisterOrganization";
import SelectOrganization from "./SelectOrganization";
import { useTranslation } from "react-i18next";

type Props = {};

function OrganizationMenu(p: Props = {}) {
  const { t } = useTranslation();
  const openRegisterOrgModal = () =>
    openModal({
      title: t("components.register-organization"),
      children: <RegisterOrganization></RegisterOrganization>,
    });
  const { data: orgs } = useGetApiOrganizationGetOrganizations();
  if (orgs == null || orgs.length === 0) {
    return (
      <PostIt>
        <Text size="lg">{t("components.apply-call-organization")}</Text>
        <Button
          variant="filled"
          color="indigo"
          mt="sm"
          radius="sm"
          style={{ float: "left", width: "100%" }}
          onClick={() => openRegisterOrgModal()}
        >
          {t("components.register-organization")}
        </Button>
      </PostIt>
    );
  } else {
    return <SelectOrganization></SelectOrganization>;
  }
}

export default OrganizationMenu;
