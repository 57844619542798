import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { useGetApiPowerBiAvailable } from "../../api/endpoints/index";
import { PowerBI } from "../../api/model";

export default function PbiReportList() {
  const { t } = useTranslation();
  const {
    data: res,
    error,
    isLoading,
  } = useGetApiPowerBiAvailable({
    groupId: "0616d1be-5b6f-419e-9e6c-85eda340ed00",
  });
  if (isLoading) return <div>{t("pages.powerbi.loading")}</div>;
  if (error) return <>{error.message}</>;
  return <RenderPbiList pbilist={res!} />;
}

function RenderPbiList({ pbilist }: { pbilist: PowerBI[] }) {
  const { t } = useTranslation();
  return (
    <table className="table table-striped" aria-labelledby="tabelLabel">
      <thead>
        <tr>
          <th>{t("pages.powerbi.name")}</th>
          <th>{t("pages.powerbi.report-id")}</th>
          <th>{t("pages.powerbi.datased-id")}</th>
          <th>{t("pages.powerbi.workspace-id")}</th>
          <th>{t("pages.powerbi.action")}</th>
        </tr>
      </thead>
      <tbody>
        {pbilist.map((pbi) => (
          <tr key={pbi.reportId}>
            <td>{pbi.reportName}</td>
            <td>{pbi.reportId}</td>
            <td>{pbi.datasetId}</td>
            <td>{pbi.workspaceId}</td>
            <td>
              {" "}
              <Link
                to={generatePath("/admin/powerbi-report/:wid/:rid", {
                  wid: pbi.workspaceId!,
                  rid: pbi.reportId!,
                })}
              >
                {t("pages.powerbi.view")}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
