import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useRefreshToken } from "../auth/AuthContext";
import { preload } from "swr";
import {
  getGetApiCallGetCallListKey,
  getGetApiOrganizationGetOrganizationsKey,
} from "../api/endpoints";
import { useTranslation } from "react-i18next";
import { Center, Text } from "@mantine/core";

const LoginCallback = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  //get the authorization_code
  const code = searchParams.get("code");
  const locale = searchParams.get("locale");
  const userState = searchParams.get("userState");
  const redirectUri = `https://${process.env.REACT_APP_HOSTNAME}/login-callback`; // "https://starimpact-dev.openimpact.it/login-callback";//get this value from config!!!
  const [refreshToken, setRefreshToken] = useRefreshToken(); //use "refreshToken" because it is declared but not used, otherwise handle it differently
  const { access_token, setAccessToken } = useAuth(); //use "access_token" because it is declared but not used, otherwise handle it differently
  //perform token exchange
  const navigate = useNavigate();

  useEffect(() => {
    console.log("exchange token in progress");
    const exchangeToken = async () => {
      await fetch("/api/Jwt/token-exchange", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          code,
          redirectUri,
        }), // body data type must match "Content-Type" header
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("access_token res: ", res);
          setAccessToken(res.token);
          setRefreshToken(res.refresh_token, 30);
          const fetcher = (url: string) =>
            fetch(url, {
              headers: {
                Authorization: "Bearer " + res.token,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("preloaded", res);
                return res;
              });

          console.log("preloading");
          preload(getGetApiCallGetCallListKey(), fetcher);
          preload(getGetApiOrganizationGetOrganizationsKey(), fetcher);
          navigate("/calls");
          return res;
        });
    };
    exchangeToken();
  }, [setAccessToken]);

  if (userState === "AuthenticatedRegistrationNotVerified") {
    return <h1>{t("components.registration-not-verified")}</h1>;
  } else if (userState === "Authenticated")
    return (
      <Center style={{ marginTop: "25%" }}>
        <Text size={24}>{t("components.logged-in")}</Text>
      </Center>
    );
  return (
    <Center style={{ marginTop: "25%" }}>
      <Text size={24}>{t("components.logged-in")}</Text>
    </Center>
  );
};
export default LoginCallback;
