import { Select, SelectProps } from "@mantine/core";
import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import { useGetApiImpactFrameworkGetStakeholderType } from "../../api/endpoints";
import Loading from "../Loading";
import {ImpactFrameworkIdContext} from "./MiniDYI";

function SelectStakeholder({
  selectedStakeholder,
  setSelectedStakeholder,
  ...props
}: {
  selectedStakeholder: string;
  setSelectedStakeholder: (stakeholder: string) => void;
} & Omit<SelectProps, "data"> &
  React.RefAttributes<HTMLInputElement>) {
  const impactFrameworkId = useContext(ImpactFrameworkIdContext);

  const {
    data: stakeholderType,
    error,
    isLoading,
  } = useGetApiImpactFrameworkGetStakeholderType({ifSetId:impactFrameworkId});
  const { t } = useTranslation();

  if (!impactFrameworkId || isLoading || error) {
    return <Loading></Loading>;
  }
  return (
    <Select
      {...props}
      withAsterisk={true}
      placeholder={t("components.mini-dyi.pick-stakeholder")}
      data={
        stakeholderType?.map((s) => {
          return {
            value: s.id!,
            label: s.name!,
          };
        })!
      }
      value={selectedStakeholder}
      onChange={setSelectedStakeholder}
    />
  );
}

export default SelectStakeholder;
