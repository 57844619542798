import {Button, Group, Table} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  deleteApiConfigDeleteConfig,
  getGetApiConfigGetConfigKey,
  getGetApiConfigGetConfigListKey, postApiConfigActivateConfig,
  useGetApiConfigGetConfigList,
} from "../../../api/endpoints";
import Loading from "../../../components/Loading";
import {IconActivity, IconCircleCheck, IconTrash} from "@tabler/icons";
type Props = {
  showClosed: boolean;
};

function CustomizeList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: configs, error, isLoading } = useGetApiConfigGetConfigList();
  if (error) {
    mutate(getGetApiConfigGetConfigListKey());
    return <Loading />;
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>{t("pages.admin.section.customize-name")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {configs &&
          Array.isArray(configs) &&
          configs.map((c, index) => {
            return (
              <tr key={index}>
                <td><img
                    src={c.logoBase64}
                    alt="logo"
                    style={{ height: "2rem", width: "auto" }}
                /></td>
                <td>{c.name}</td>
                <td>{c.isActive ? "ACTIVE" : ""}</td>
                <td>
                  <Group>
                  <Button
                    variant="filled"
                    color="green"
                    radius="sm"
                    onClick={() => {
                      postApiConfigActivateConfig({configId:c.id}).then(()=>{
                      mutate(getGetApiConfigGetConfigKey())
                      mutate(getGetApiConfigGetConfigListKey())
                      });
                    }}
                    leftIcon={<IconCircleCheck />}
                  >
                    {t("pages.admin.section.activate")}
                  </Button>
                  <Button
                    variant="filled"
                    color="blue"
                    radius="sm"
                    onClick={() => {
                      navigate(`/admin/customize`);
                    }}
                  >
                    {t("pages.admin.section.edit")}
                  </Button>
                  <Button
                    variant="filled"
                    color="red"
                    radius="sm"
                    onClick={() => {
                      deleteApiConfigDeleteConfig({configId:c.id}).then(()=>{
                        mutate(getGetApiConfigGetConfigKey())
                        mutate(getGetApiConfigGetConfigListKey())
                      });    
                    }}
                    leftIcon={<IconTrash/>}
                  >
                    {t("pages.admin.section.remove")}
                  </Button>
                  </Group>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

export default CustomizeList;
