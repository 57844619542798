import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AXIOS_INSTANCE } from "../api/axios-instance";
import { AxiosRequestConfig } from "axios";
import { mutate } from "swr";
import { MyUserInfo, UserDto } from "../api/model";
import useCookie from "../hooks/useCookie";
import { getGetApiUserKey, useGetApiUser } from "../api/endpoints";
import Loading from "../components/Loading";

interface AuthContextType {
  user?: UserDto;
  access_token: string | null;
  setAccessToken: (token: string | null) => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
}

let AuthContext = createContext<AuthContextType>(null!);

const useAuth = () => useContext(AuthContext);

export const useAccessToken = () => {
  return useCookie({
    key: "access_token",
    defaultValue: "",
  });
};
export const useRefreshToken = () => {
  return useCookie({
    key: "refresh_token",
    defaultValue: "",
  });
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [accessToken, updateAccessToken] = useAccessToken();
  const [value, setValue] = useState<AuthContextType>({
    access_token: null,
    setAccessToken: (token: string | null) => {
      updateAccessToken(token, 30);
      mutate(getGetApiUserKey());
    },
    isAuthenticated: false,
    isAdmin: false,
    isLoading: true,
  });

  const { data: user } = useGetApiUser({
    swr: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use(
      (config) =>
        ({
          ...config,
          headers: accessToken
            ? {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
              }
            : config.headers,
        } as AxiosRequestConfig<any>),
      (res) => {
        if (res.code === "401") {
          navigate("/", { replace: true });
        }
      }
    );

    //trigger user revalidation
    mutate(getGetApiUserKey());

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [accessToken, navigate]);

  useEffect(() => {
    //todo: handle the case when we have an access token but we don't get a user, set the loading to false.
    setValue({
      ...value,
      user,
      access_token: accessToken,
      isAuthenticated: !!accessToken && !!user,
      isAdmin:
        user?.roles?.filter((c) => c.toLowerCase() === "admin").length === 1,
      isLoading: !user && !!accessToken,
    });
  }, [user, accessToken]);

  return <AuthContext.Provider value={value!}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.isLoading &&  (!auth.isAuthenticated || !auth.access_token || auth.access_token == "" || auth.access_token == "null")) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (auth.isLoading) return <Loading />;
  return children;
}

export { useAuth, RequireAuth, AuthProvider };
