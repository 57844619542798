import { Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

const MiniDyiIntro = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title order={2}>{t("components.introduction")}</Title>
      <Space h="md" />
      <Text size={"md"} pb={10}>
        {t("components.mini-dyi.design-your-impact")}
      </Text>
      {/*<iframe
        width={"100%"}
        style={{ aspectRatio: "16/9" }}
        src="https://www.youtube.com/embed/a3ipgTPguXs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>*/}
    </>
  );
};
export default MiniDyiIntro;
