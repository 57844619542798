import {Accordion, Flex, Text, Title} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { ImpactFrameworkDTO } from "../../api/model";
import { useTranslation } from "react-i18next";

const AccordionOutcomes = ({
  framework,
}: {
  framework: ImpactFrameworkDTO;
}) => {
  const { t } = useTranslation();
  const TextBold = ({ value }: { value: string | number | undefined }) => {
    return <span style={{ fontWeight: 600 }}>{value}</span>;
  };
  const TextLight = ({ value }: { value: string | number | undefined }) => {
    return <span style={{ fontWeight: "lighter" }}>{value}</span>;
  };

  return (
    <>
      {framework.stakeholders?.map((r, i) => {
        <Text key={i}>
          <TextBold value={r.name ?? "-"} />
          <TextLight value={r.description ?? "-"} />
        </Text>;
      })}
      {framework.outcomes?.sort((x,y)=>x.name?.localeCompare(y.name!)!).map((r, i) => (
        <>
          <Accordion key={i} mt={5} chevron={<IconChevronDown size={20} />}>
            <Accordion.Item value={r.name ?? i.toString()}>
              <Accordion.Control mb={5}>
                <Flex direction={"column"}>
                  <Flex align={"center"}>
                    <TextBold value={t("pages.admin.outcome-name")} />
                    <Text style={{ marginLeft: 5 }}>{r.name ?? "-"}</Text>
                  </Flex>
{/*                  <span style={{ marginLeft: 80 }}>
                    <TextLight value={r.description ?? "-"} />
                  </span>*/}
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ marginLeft: 10, marginTop: -10 }}>
                  <Text>
                    <TextBold value={t("pages.admin.outcome-sdg")} />
                    <TextLight value={r.sdg ?? "-"} />
                  </Text>
                  <>
                    <Flex direction={"column"} style={{ marginTop: 10 }}>
                      <TextBold value={t("pages.admin.outcome-indicators")}
                        />
                      <Text>
                        {r.indicators?.map((e) => (
                          <>
                            <div style={{ marginLeft: 85, marginTop: 21 }}>
                              <hr />
                              <TextBold value={e.name ?? "-"} />
                              <Text>
                                <TextLight value={e.description ?? "-"} />
                              </Text>
                              <Flex
                                direction={"column"}
                                style={{ marginTop: 10 }}
                              >
                                <Text>
                                  <Text mb={-35}>
                                    <TextBold
                                      value={t("pages.admin.outcome-proxies")}
                                    />
                                  </Text>
                                  {e.proxies?.map((p) => (
                                    <>
                                      <div
                                        style={{
                                          marginLeft: 55,
                                          marginTop: 11,
                                        }}
                                      >
                                        <TextLight value={p.name ?? "-"} />
                                        <Text>
                                          {t("pages.admin.outcome-value")}
                                          <TextLight
                                            value={p.defaultValue ?? "-"}
                                          />
                                        </Text>
                                        <Text>
                                          {t("pages.admin.outcome-source")}
                                          <TextLight
                                            value={p.sourceName ?? "-"}
                                          />
                                        </Text>
                                      </div>
                                    </>
                                  ))}
                                </Text>
                              </Flex>
                            </div>
                          </>
                        ))}
                      </Text>
                    </Flex>
                  </>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </>
      ))}
    </>
  );
};

export default AccordionOutcomes;
