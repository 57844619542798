import { Group, Image } from "@mantine/core";

const Images = [
  {
    id: 1,
    path: "../01_ita.png",
  },
  {
    id: 2,
    path: "../02_ita.png",
  },
  {
    id: 3,
    path: "../03_ita.png",
  },
  {
    id: 4,
    path: "../4.png",
  },
  {
    id: 5,
    path: "../5.png",
  },
  {
    id: 6,
    path: "../6.png",
  },
  {
    id: 7,
    path: "../7.png",
  },
  {
    id: 8,
    path: "../8.png",
  },
  {
    id: 9,
    path: "../9.png",
  },
  {
    id: 10,
    path: "../10.png",
  },
  {
    id: 11,
    path: "../11.png",
  },
  {
    id: 12,
    path: "../12.png",
  },
  {
    id: 13,
    path: "../13.png",
  },
  {
    id: 14,
    path: "../14.png",
  },
  {
    id: 15,
    path: "../15.png",
  },
  {
    id: 16,
    path: "../16.png",
  },
  {
    id: 17,
    path: "../17_ita.png",
  },
];
type SdgId = {
  id: number;
};

function SDGImage({ id }: SdgId) {
  const imagePaths = Images.filter((x) => x.id === id);

  return (
    <div>
      {imagePaths.map((path) => {
        return (
          <Group key={path?.id}>
            <Image width={40} height={40} src={path?.path} />
          </Group>
        );
      })}
    </div>
  );
}

export default SDGImage;
