import { create } from "zustand";
import {
  IndicatorTypeDTO,
  MiniDyiIndicatorDTO,
  MiniDyiOutcomeDTO,
} from "../../api/model";

import { mountStoreDevtool } from "simple-zustand-devtools";

export type miniDyiStoreState = {
  stepId: string;
  sdg: number[];
  budget: number;
  budgetSpent: number;
  selectedOutcomes: (MiniDyiOutcomeDTO | MiniDyiCustomOutcome)[];
};

export function compareOutcomes(
  a: MiniDyiCustomOutcome,
  b: MiniDyiCustomOutcome
) {
  if (a.customKey && b.customKey && a.customKey === b.customKey) {
    return true;
  } else return a.outcomeId && b.outcomeId && a.outcomeId == b.outcomeId;
}
export function compareIndicators(
  a: MiniDyiCustomIndicator,
  b: MiniDyiCustomIndicator
) {
  if (
    (a.customKey && b.customKey && a.customKey == b.customKey) ||
    (a.id && b.indicatorId && a.id == b.indicatorId) ||
    (b.id && a.indicatorId && b.id == a.indicatorId) ||
    (b.indicatorId && a.indicatorId && b.indicatorId == a.indicatorId)
  ) {
    return true;
  } else {
    return false;
  }
}

export type MiniDyiCustomOutcome = MiniDyiOutcomeDTO & {
  customKey?: string;
  indicators?: MiniDyiCustomIndicator[];
};
export type MiniDyiCustomIndicator = MiniDyiIndicatorDTO &
  IndicatorTypeDTO & {
    customKey?: string;
  };

type miniDyiStoreActions = {
  cleanup: () => void;
  hydrate: (data: string) => void;
  setStepId: (stepId: string) => void;
  setBudget: (budget: number) => void;
  setBudgetSpent: (budgetSpent: number) => void;
  toggleSdg: (sdgId: number) => void;
  toggleOutcome: (outcome: MiniDyiOutcomeDTO) => void;
  addCustomOutcome: (outcome: MiniDyiCustomOutcome) => void;
  toggleIndicator: (
    outcome: MiniDyiOutcomeDTO,
    indicator: MiniDyiIndicatorDTO
  ) => void;
  setOutcomeField: <T extends keyof MiniDyiOutcomeDTO>(
    outcomeId: MiniDyiCustomOutcome,
    field: T,
    value: MiniDyiOutcomeDTO[T]
  ) => void;
  setIndicatorField: <T extends keyof MiniDyiIndicatorDTO>(
    outcomeId: MiniDyiCustomOutcome,
    indicator: MiniDyiCustomIndicator,
    field: T,
    value: MiniDyiIndicatorDTO[T]
  ) => void;
};
export type miniDyiStoreType = miniDyiStoreState & miniDyiStoreActions;

const useMiniDyiStore = create<miniDyiStoreType>((set) => ({
  budget: 0,
  setBudget: (budget) => set(() => ({ budget })),
  budgetSpent: 0,
  setBudgetSpent: (budgetSpent: number) => set(() => ({ budgetSpent })),
  stepId: "",
  setStepId: (stepId: string) => set(() => ({ stepId })),
  sdg: [],
  selectedOutcomes: [],
  cleanup: () =>
    set(() => ({
      stepId: "",
      sdg: [],
      budget: 0,
      selectedOutcomes: [],
    })),
  hydrate: (newState: string) => set(() => JSON.parse(newState)),
  toggleSdg: (sdgId: number) =>
    set((state) => ({
      sdg: state.sdg.includes(sdgId)
        ? state.sdg.filter((id) => id != sdgId)
        : state.sdg.length < 4
        ? [...state.sdg, sdgId]
        : state.sdg,
    })),
  toggleOutcome: (outcome: MiniDyiOutcomeDTO) =>
    set((state) => ({
      selectedOutcomes: state.selectedOutcomes.filter((so) =>
        compareOutcomes(so, outcome)
      ).length
        ? state.selectedOutcomes.filter((so) => !compareOutcomes(so, outcome))
        : state.selectedOutcomes.filter((so) => so.sdg === outcome.sdg).length <
          4
        ? [...state.selectedOutcomes, outcome]
        : state.selectedOutcomes,
    })),
  addCustomOutcome: (outcome: MiniDyiOutcomeDTO) =>
    set((state) => ({
      selectedOutcomes: [...state.selectedOutcomes, outcome],
    })),
  toggleIndicator: (
    outcome: MiniDyiOutcomeDTO,
    indicator: MiniDyiIndicatorDTO
  ) =>
    set((state) => {
      return {
        selectedOutcomes: state.selectedOutcomes.map((out) => {
          if (compareOutcomes(out, outcome)) {
            out.indicators = out?.indicators?.filter((i) =>
              compareIndicators(i, indicator)
            ).length
              ? out?.indicators?.filter((i) => !compareIndicators(i, indicator))
              : out.indicators?.length! > 0
              ? [indicator, ...out.indicators!]
              : [indicator];
          }
          return out;
        }),
      };
    }),
  setOutcomeField: (outcome, field, value) =>
    set((state) => ({
      selectedOutcomes: state.selectedOutcomes.map((out) => {
        if (compareOutcomes(out, outcome)) {
          out[field] = value;
        }
        return out;
      }),
    })),
  setIndicatorField: (outcome, indicator, field, value) =>
    set((state) => ({
      selectedOutcomes: state.selectedOutcomes.map((out) => {
        if (compareOutcomes(out, outcome)) {
          out.indicators = out?.indicators?.map((ind) => {
            if (compareIndicators(indicator, ind)) {
              ind[field] = value;
            }
            return ind;
          });
        }
        return out;
      }),
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("MiniDyiStore", useMiniDyiStore);
}
export default useMiniDyiStore;
