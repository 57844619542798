import { Button, Tabs, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGetApiImpactFrameworkGetImpactFrameworkByIdKey,
  useGetApiImpactFrameworkGetImpactFrameworkById,
} from "../../api/endpoints";
import { mutate } from "swr";
import Loading from "../../components/Loading";
import { IconArrowLeft } from "@tabler/icons";
import AccordionOutcomes from "./AccordionOutcomes";
import { useTranslation } from "react-i18next";
import AccordionStakeholders from "./AccordionStakeholders";

const FrameworkView = () => {
  const {id} = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: framework,
    isLoading,
    error,
  } = useGetApiImpactFrameworkGetImpactFrameworkById({
    ImpactFrameworkId: id,
  });
  if (error) {
    mutate(getGetApiImpactFrameworkGetImpactFrameworkByIdKey());
    return <Loading />;
  }
  if (isLoading || !framework) {
    return <Loading />;
  }
  console.log("FRAMEWORK: ", framework);

  return (
    <>
      <Button
        variant="subtle"
        style={{ marginLeft: -14 }}
        leftIcon={<IconArrowLeft />}
        onClick={() => navigate(-1)}
      >
        {t("pages.admin.go-back")}
      </Button>
      <Text fw={600} size={24} mt={16}>
        {framework.name}
      </Text>
      <Text>{framework.description}</Text>
        <Tabs defaultValue={"outcome"} mt={16}>
            <Tabs.List>
                
                <Tabs.Tab value={"outcome"} >Outcome</Tabs.Tab>
                <Tabs.Tab value={"stakeholder"}>Stakeholder</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value={"outcome"}>
                  <AccordionOutcomes framework={framework} />
            </Tabs.Panel>
            <Tabs.Panel value={"stakeholder"}>
                  <AccordionStakeholders framework={framework} />
            </Tabs.Panel>
        </Tabs>
    </>
  );
};

export default FrameworkView;
