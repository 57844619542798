import { Center, Group, Image, Input, Loader } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconX } from "@tabler/icons";
import { ChangeEvent, useState } from "react";

const ImageUploader = ({
  form,
}: {
  form: UseFormReturnType<any, (values: any) => any>;
}) => {
  const [base64Image, setBase64Image] = useState("");
  const [loader, setLoader] = useState(false);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      setBase64Image(base64Image as string);
      form.setFieldValue("logoBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImageUpload = () => {
    setLoader(true);
    setBase64Image("");
    form.setFieldValue("logoBase64", "");
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  if (loader) {
    return (
      <Center mt={20} mb={10}>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Group position="center" mt={20} mb={10}>
        <Input
          styles={{
            input: { border: 0, minHeight: "2.5rem" },
          }}
          type="file"
          sx={{
            ".mantine-Input-input": {
              backgroundColor: "transparent",
            },
          }}
          onChange={handleImageUpload}
          accept="image/*"
        />
      </Group>
      {base64Image && (
        <Center mt={10} mb={20}>
          <Image src={base64Image} width={64} />
          <IconX
            style={{ marginLeft: 20, color: "grey", cursor: "pointer" }}
            onClick={removeImageUpload}
          />
        </Center>
      )}
    </>
  );
};

export default ImageUploader;
