import { Button, Center, Loader, Table, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useGetApiStepGetSteps } from "../../api/endpoints";
import { useTranslation } from "react-i18next";

function StepsListReport() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { data: steps } = useGetApiStepGetSteps({
    callTypeId: params.callTypeId,
  });

  if (!steps) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const StepList = () => {
    return (
      <Table mt={20}>
        <tbody>
          {steps.filter((s) => s.stepType!.name == "survey").length ? (
            steps
              .filter((s) => s.stepType!.name == "survey")
              .map((s) => {
                return (
                  <tr key={s.id} style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <td>{s.name}</td>
                    <td>
                      <Button
                        variant="subtle"
                        color="blue"
                        rightIcon={<IconArrowRight />}
                        radius="sm"
                        style={{ float: "right", marginRight: "20px" }}
                        onClick={() => {
                          navigate(`/admin/report/${params.callId}/${s.id}`);
                        }}
                      >
                        {t("pages.admin.go-report")}
                      </Button>
                    </td>
                  </tr>
                );
              })
          ) : (
            <Center>
              <Text>
                        {t("pages.admin.no-survey-call")}
              </Text>
            </Center>
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <Button
        variant="subtle"
        style={{ marginLeft: -14 }}
        leftIcon={<IconArrowLeft />}
        onClick={() => navigate("/admin", { state: "calls" })}
      >
        {t("pages.admin.go-back")}
      </Button>
      <StepList />
    </>
  );
}

export default StepsListReport;
