import React, { useContext } from "react";
import { AppConfigDTO } from "../api/model";
import { useGetApiConfigGetConfig } from "../api/endpoints";
import { Center, Flex, Loader } from "@mantine/core";

const ConfigContext = React.createContext<AppConfigDTO | undefined>(undefined);

export function ConfigProvider(props: { children: React.ReactNode }) {
  const { data: config, isLoading, error } = useGetApiConfigGetConfig();
  if (error) console.log(error);
  if (isLoading) {
    console.log("loading config");
    return (
      <Center style={{ marginTop: "25%" }}>
        <Flex direction={"column"} align={"center"}>
          <Loader />
          Loading config...
        </Flex>
      </Center>
    );
  }
  return <ConfigContext.Provider value={config} {...props} />;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  if (config === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return config;
}
