import { TextInput, Button, Group } from "@mantine/core";
import { useForm } from "@mantine/form";
import { SJSurveyTypeDTO } from "../../dto/sj-survey-type-dto";
import { postApiSJSurveyCreateSurveyType } from "../../api/endpoints";
import { useTranslation } from "react-i18next";

function CreateSurveyType() {
  const { t } = useTranslation();
  const form = useForm<SJSurveyTypeDTO>({
    validate: {},
  });
  return (
    <form
      onSubmit={form.onSubmit((values) =>
        postApiSJSurveyCreateSurveyType(values)
      )}
    >
      <TextInput
        withAsterisk
        label={t("pages.admin.name")}
        placeholder={t("pages.admin.survey-name")}
        {...form.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label={t("pages.admin.description")}
        placeholder={t("pages.admin.survey-description")}
        {...form.getInputProps("description")}
      />
      <Group position="right" mt="md">
        <Button type="submit">{t("pages.admin.submit")}</Button>
      </Group>
    </form>
  );
}

export default CreateSurveyType;
